import { useRoute, useRouter } from 'vue-router';
import { computed, ComputedRef, provide, Ref, ref, watch } from 'vue';
import { injectionKey, injectStrict } from '~/common/utils/inject';
import { AppDataInjectionKey } from '~/common/utils/useAppData';

interface Tab {
  icon: string;
  path: string;
  title: string;
}

export const TabInjectionKey = injectionKey<{
  openInNewTab: (path: string, title: string, icon: string) => void;
  closeTab: (tab: Tab) => void;
  openTabs: Ref<Tab[]>;
  homeRoute: Ref<string>;
}>();

export default function useTabs() {
  const sessionStorageKey = 'portal.open_tabs';
  const route = useRoute();
  const { isAdmin } = injectStrict(AppDataInjectionKey);

  // refs
  const openTabs = ref<Tab[]>([]);
  const homeRoute = ref<string>(isAdmin.value ? '/admin' : '/projects');

  // watchers
  watch(
    () => openTabs.value.length,
    () => {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(openTabs.value));
    }
  );

  watch(route, (newValue) => {
    if (newValue.meta.isHomeRoute) {
      setHomeRoute(newValue.path);
    }
  });

  // init
  initHomeRoute();
  initTabsFromSessionStorage();

  // methods
  function initHomeRoute() {
    if (route.meta.isHomeRoute) {
      setHomeRoute(route.path);
    }
  }

  function setHomeRoute(path: string) {
    homeRoute.value = path;
  }

  function initTabsFromSessionStorage() {
    const tabs = sessionStorage.getItem(sessionStorageKey);
    if (tabs && tabs.length) {
      openTabs.value = JSON.parse(tabs);
    }
  }

  function openInNewTab(path: string, title: string, icon: string) {
    if (openTabs.value.find((i) => i.path === path)) {
      return;
    }
    openTabs.value.push({
      icon,
      path,
      title
    });
  }

  function closeTab(tab: Tab) {
    const index = openTabs.value.indexOf(tab);
    if (index >= 0) {
      openTabs.value.splice(index, 1);
    }
  }

  // provide
  provide(TabInjectionKey, {
    openInNewTab,
    closeTab,
    openTabs,
    homeRoute
  });

  return {
    closeTab,
    openTabs,
    homeRoute
  };
}

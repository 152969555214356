import './style/style.css';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import setupI18n from '~/i18n';
import { AppDataInjectionKey, useAppData } from '~/common/utils/useAppData';

const root = document.querySelector('#app') as HTMLElement;
const appData = useAppData(root);

const app = createApp(App);

app
  .use(router)
  .use(setupI18n(appData.locale))
  .provide(AppDataInjectionKey, appData);

app.mount('#app');

import useCsrf from '~/common/forms/useCsrf';
import { useToast } from '~/notifications/useToast';

interface ApiArgs {
  query?: Record<string, string>;
  config?: Record<string, object | string>;
  body?: object;
}

export default function useApi<T>(
  endpoint: string
): Record<'get' | 'post' | 'put' | 'delete', (args?: ApiArgs) => Promise<T>> {
  const baseUrl = '/api/';
  const { token } = useCsrf();

  async function get(args?: ApiArgs): Promise<T> {
    return fetchBase(`${baseUrl}${endpoint}`, {
      method: 'GET',
      ...args?.config
    });
  }

  async function post(args?: ApiArgs): Promise<T> {
    return fetchBase(`${baseUrl}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': token.value
      },
      method: 'POST',
      ...args?.config,
      body: args ? JSON.stringify(args.body) : undefined
    });
  }

  async function put(args?: ApiArgs): Promise<T> {
    return fetchBase(`${baseUrl}${endpoint}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': token.value
      },
      method: 'PUT',
      ...args?.config,
      body: args ? JSON.stringify(args.body) : undefined
    });
  }

  async function remove(args?: ApiArgs): Promise<T> {
    return fetchBase(`${baseUrl}${endpoint}`, {
      headers: { 'x-csrf-token': token.value },
      method: 'DELETE',
      ...args?.config
    });
  }

  async function fetchBase(input: string, config: object) {
    return fetch(input, config)
      .then(async (res) => {
        if (res.ok) {
          const contentType = res.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            return res.json();
          }
          return res;
        }
        const body = await res.json();
        throw body.message || '';
      })
      .catch((error: string) => {
        useToast(error, 'danger');
        throw new Error(error);
      });
  }

  return {
    get: (args?: ApiArgs) => get(args),
    post: (args?: ApiArgs) => post(args),
    put: (args?: ApiArgs) => put(args),
    delete: (args?: ApiArgs) => remove(args)
  };
}

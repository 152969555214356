import { createI18n } from 'vue-i18n';
import { Locale } from '@common/i18n/locale';
import en from './messages/en';
import de from './messages/de';

export default function setupI18n(locale: Locale | undefined) {
  return createI18n({
    legacy: false,
    locale: locale || 'de',
    fallbackLocale: 'de',
    runtimeOnly: false,
    messages: {
      en: {
        ...en
      },
      de: {
        ...de
      }
    },
    datetimeFormats: {
      de: {
        short: {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        },
        long: {
          weekday: 'long',
          month: 'long',
          day: 'numeric'
        }
      }
    }
  });
}

import { computed, ComputedRef, provide, Ref, ref } from 'vue';
import { ProjectDto } from '@common/project/project.dtos';
import { injectionKey, injectStrict } from '~/common/utils/inject';
import { AppDataInjectionKey } from '~/common/utils/useAppData';
import useUserRepository from '~/user/useUserRepository';
import useProjectRepository from '~/project/useProjectRepository';

interface ProjectsInjection {
  projects: Ref<ProjectDto[]>;
  isLoading: Ref<boolean>;
  hasOnlyOneProject: ComputedRef<boolean>;
  deleteProject: (id: number) => void;
  createNewDefaultProject: () => void;
  refresh: () => void;
}

export const ProjectsInjectionKey = injectionKey<ProjectsInjection>();

export default function useProjects() {
  const { getProjects } = useUserRepository();
  const {
    getAllProjects: getProjectsAsAdmin,
    removeProject,
    createProject,
    fetchDefaultSettings
  } = useProjectRepository();

  const { isAdmin, currentUser } = injectStrict(AppDataInjectionKey);

  // refs
  const isLoading = ref<boolean>(true);
  const projects = ref<ProjectDto[]>([]);

  // computed
  const hasOnlyOneProject = computed(() => projects.value.length === 1);

  // init
  init();

  // methods
  function refresh() {
    loadProjects();
  }

  async function loadProjects() {
    isLoading.value = true;
    if (!isAdmin.value) {
      projects.value = await getProjects();
    } else {
      projects.value = await getProjectsAsAdmin();
    }
    isLoading.value = false;
  }

  async function init() {
    if (currentUser.value) {
      await loadProjects();
    }
    isLoading.value = false;
  }

  async function deleteProject(id: number) {
    await removeProject(id);
    await loadProjects();
  }

  async function createNewDefaultProject() {
    isLoading.value = true;
    const initialSettings = await fetchDefaultSettings();

    if (!initialSettings) {
      return;
    }

    initialSettings.title = 'Neues Projekt';
    await createProject(initialSettings);
    refresh();
  }

  // provide
  provide(ProjectsInjectionKey, {
    createNewDefaultProject,
    projects,
    isLoading,
    hasOnlyOneProject,
    deleteProject,
    refresh
  });

  return {
    isLoading
  };
}

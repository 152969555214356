<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_101_41459)">
      <path
        d="M16.0128 -0.019043C7.17439 -0.019043 0.0127869 7.14256 0.0127869 15.981C0.0127869 24.8194 7.17439 31.981 16.0128 31.981C24.8512 31.981 32.0128 24.8194 32.0128 15.981C32.0128 7.14256 24.8512 -0.019043 16.0128 -0.019043ZM9.79199 15.373L8.54399 14.125L9.79199 12.877L11.04 14.125L9.79199 15.373ZM20.5504 23.6226L19.296 24.877L16.128 21.709L12.9792 24.8578L11.7248 23.6034L14.8736 20.4546L11.712 17.2866L12.9664 16.0322L16.1344 19.2002L18.0544 17.2802L11.712 10.9442L16.128 6.52816L20.5504 10.9506L17.3824 14.1186L20.5504 17.2866L17.376 20.461L20.5504 23.6226ZM22.464 15.373L21.216 14.125L22.464 12.877L23.712 14.125L22.464 15.373Z"
        fill="currentColor"
      />
      <path
        d="M16.13 9.03398L14.2157 10.9482L16.1345 12.867L18.0488 10.9528L16.13 9.03398Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_101_41459">
        <rect width="32" height="32" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped></style>

import { computed, ComputedRef, provide, Ref, ref } from 'vue';
import { RouteRecord, useRoute, useRouter } from 'vue-router';
import { injectionKey } from '~/common/utils/inject';

export interface NavigationElement {
  route: RouteRecord;
  path: string;
  name: string;
  icon: string;
}

export const NavigationInjectionKey = injectionKey<{
  navigationElements: Ref<NavigationElement[]>;
  indexOfActiveNavigationElement: ComputedRef<number>;
  setNavigation: (elements: string[], i?: number) => void;
  replaceRoute: () => void;
}>();

export default function useNavigation() {
  const route = useRoute();
  const router = useRouter();
  const routes = router.getRoutes();

  const navigationElements = ref<NavigationElement[]>([]);
  const firstNavElementIndex = ref<number>(0);

  const indexOfActiveNavigationElement = computed((): number => {
    const element = navigationElements.value.find(
      (e) => e.route.name === route.name
    );
    if (!element) return -1;
    return navigationElements.value.indexOf(element);
  });

  function setNavigation(items: string[], first = 0) {
    navigationElements.value = [];
    firstNavElementIndex.value = first;

    items.forEach((item, j) => {
      const route = routes.find((r) => r.name === item);

      if (route && route.meta.isHidden !== true) {
        // const isBackLink = first !== 0 && j === 0;
        navigationElements.value.push({
          route,
          path: transformedPath(route.path),
          name: route.meta.name as string,
          icon: route.meta.icon as string
        });
      }
    });
    return replaceRoute();
  }

  function transformedPath(path: string) {
    if (typeof route.params.projectId === 'string') {
      return path.replaceAll(':projectId', route.params.projectId);
    }
    return path;
  }

  async function replaceRoute() {
    const routeInNavigation = navigationElements.value.find(
      (e) => e.route.name === route.name
    );

    if (!routeInNavigation) {
      const firstNavElement =
        navigationElements.value[firstNavElementIndex.value];
      if (firstNavElement) {
        await router.replace(firstNavElement.route);
      }
    }
  }

  provide(NavigationInjectionKey, {
    navigationElements,
    indexOfActiveNavigationElement,
    setNavigation,
    replaceRoute
  });

  return {};
}

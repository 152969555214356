<template>
  <aside class="side-navigation" :class="{ '-collapsed': isCollapsed }">
    <div class="topbar">
      <RouterLink class="logo" :to="homeRoute">
        <GridonicLogo />
        <p class="text">Gridonic</p>
      </RouterLink>
      <LucidIcon
        :size="24"
        name="menu-arrow-left"
        class="collapse"
        @click="isCollapsed = !isCollapsed"
      />
    </div>
    <nav class="navigation">
      <span
        v-if="indexOfActiveNavigationElement >= 0"
        class="activebackground"
        :style="{
          '--transform': `calc(${indexOfActiveNavigationElement} * var(--step))`
        }"
      />
      <ul class="list">
        <li
          v-for="(n, i) in navigationElements"
          :key="`nav-element-${i}`"
          :class="{ '-active': route.name === n.route.name }"
          class="element"
        >
          <RouterLink class="link" :to="n.path">
            <LucidIcon
              class="icon"
              color="var(--color-neutral-300)"
              :name="n.icon"
            />
            <span class="title">{{ n.name }}</span>
          </RouterLink>
        </li>
      </ul>
    </nav>
    <div class="bottombar">
      <a class="link" href="/auth/logout" target="_self">
        <LucidIcon
          class="icon-button-primary"
          name="door-open"
          :size="18"
          :stroke-width="1.3333"
        />
        <span class="title">Logout</span>
      </a>
      <small class="version">Gridonic Portal {{ version }}</small>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { onMounted, ref, watchEffect } from 'vue';
import GridonicLogo from '~/common/icons/GridonicLogo.vue';
import IconCollapse from '~/common/icons/IconCollapse.vue';
import IconLogout from '~/common/icons/IconLogout.vue';
import { injectStrict } from '~/common/utils/inject';
import { NavigationInjectionKey } from '~/tool/ia/useNaviagtion';
import { AppDataInjectionKey } from '~/common/utils/useAppData';
import LucidIcon from '~/common/LucidIcon.vue';
import MaskIcon from '~/common/MaskIcon.vue';

const { version, isAdmin } = injectStrict(AppDataInjectionKey);
const { navigationElements, indexOfActiveNavigationElement } = injectStrict(
  NavigationInjectionKey
);

const route = useRoute();
const isCollapsed = ref<boolean>(false);
const homeRoute = isAdmin.value ? { name: 'Admin' } : { name: 'Projects' };

const screenSize = ref<number>(window.innerWidth);

watchEffect(() => {
  if (isCollapsed.value === true) {
    document.body.style.setProperty('--side-navigation-width', '7rem');
  } else {
    document.body.style.setProperty('--side-navigation-width', '18rem');
  }
});

onMounted(() => {
  window.addEventListener('resize', updateCollapseState);
  updateCollapseState();
});

function updateCollapseState() {
  const breakpoint = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue('--screen-medium');
  if (
    window.innerWidth <= Number(breakpoint) &&
    window.innerWidth <= screenSize.value &&
    !isCollapsed.value
  ) {
    isCollapsed.value = true;
  }
  if (
    window.innerWidth >= Number(breakpoint) &&
    window.innerWidth >= screenSize.value &&
    isCollapsed.value
  ) {
    isCollapsed.value = false;
  }
  screenSize.value = window.innerWidth;
}
</script>

<style scoped>
.side-navigation {
  height: 100%;
  overflow: clip;
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  position: fixed;
  inset: 0;
  width: var(--side-navigation-width);
  background-color: var(--color-neutral-900);

  &.-collapsed {
    > .topbar,
    > .bottombar,
    > .navigation {
      width: calc(5rem - 2rem);
    }

    > .topbar {
      > .logo {
        display: none;
      }

      > .collapse {
        transform: rotate(180deg) scale(1);

        &:hover {
          transform: rotate(180deg) scale(1.1);
        }
      }
    }

    > .navigation {
      > .list {
        > .element {
          .title {
            display: none;
          }
        }
      }
    }

    > .bottombar {
      > .link {
        > .title {
          display: none;
        }
      }

      > .version {
        visibility: hidden;
      }
    }
  }

  > .topbar,
  > .bottombar,
  > .navigation {
    width: calc(18rem - 3rem);
  }

  > .topbar {
    padding: 0 0 4rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .logo {
      color: var(--color-white);
      display: flex;
      gap: 0.5rem;
      align-items: center;

      > .text {
        @mixin text-base var(--font-weight-semibold);
      }
    }

    > .collapse {
      cursor: pointer;
      transform: rotate(0) scale(1);
      transition: transform var(--base-transition-duration);

      &:hover {
        transform: rotate(0) scale(1.1);
      }
    }
  }

  > .bottombar {
    background-color: var(--color-neutral-900);
    border-top: 1px solid var(--color-neutral-600);
    padding: 2rem 0 0 0;
    position: absolute;
    bottom: 1rem;

    > .link {
      color: var(--color-neutral-500);
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      width: fit-content;

      > .title {
        @mixin text-sm var(--font-weight-semibold);

        hyphens: auto;
        color: var(--color-white);
      }
    }

    > .version {
      @mixin text-xs;

      display: block;
      padding-top: 2rem;
      font-size: 0.6rem;
      color: var(--color-neutral-400);
      white-space: nowrap;
    }
  }

  > .navigation {
    @mixin hide-scrollbar;

    overflow-y: auto;
    max-height: calc(100% - 11rem);

    > .activebackground {
      --step: 4.75rem;
      --transform: calc(0 * var(--step));

      position: absolute;
      top: 0;
      width: 100%;
      background-image: var(--gradient-brand);
      height: 3.75rem;
      border-radius: var(--base-border-radius-sm);
      transition: transform 250ms;
      transform: translateY(var(--transform));
    }

    > .list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      list-style: none;
      padding-bottom: 2rem;

      > .element {
        padding: 0 0.75rem;
        height: 3.75rem;
        transition: color 250ms;
        border: 1px solid transparent;
        border-radius: var(--base-border-radius-sm);

        &:not(.-active) {
          color: var(--color-neutral-300);

          &:hover {
            background:
              linear-gradient(
                  0deg,
                  var(--color-neutral-900) 0%,
                  var(--color-neutral-900) 100%
                )
                padding-box,
              var(--gradient-brand) border-box;
          }
        }

        &.-active {
          color: var(--color-white);

          > .link {
            > .icon {
              > :deep(.svg) {
                stroke: var(--color-white);
              }
            }
          }
        }

        > .link {
          color: currentColor;
          display: flex;
          align-items: center;
          gap: 0.75rem;
          height: 3.75rem;

          > .icon {
            flex-shrink: 0;
          }

          > .title {
            @mixin text-sm var(--font-weight-semibold);

            hyphens: auto;
          }
        }
      }
    }
  }
}
</style>

type ToastType = 'danger' | 'warning' | 'notification' | 'success';

export interface Toast {
  message: string;
  type: string;
}

export function useToast(message: string, type: ToastType) {
  const event = getToastEvent(message, type);
  window.dispatchEvent(event);
}

export function getToastEvent(message: string, type: string) {
  return new CustomEvent('toast', { detail: { message, type } });
}

<template>
  <div class="toast-section">
    <div
      v-for="(t, i) in toasts"
      :key="`toast-${i}`"
      class="toast"
      :class="[`-${t.type}`]"
    >
      <p class="message">
        {{ t.message }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Toast } from '~/notifications/useToast';

interface ToastEvent extends CustomEvent {
  detail: Toast;
}

const timeout = 6000;

const toasts = ref<Toast[]>([]);

// @ts-ignore
window.addEventListener('toast', (e: ToastEvent) => {
  createToast(e.detail);
});

function createToast(toast: Toast) {
  toasts.value.push(toast);

  setTimeout(() => {
    const i = toasts.value.indexOf(toast);
    toasts.value.splice(i, 1);
  }, timeout);
}
</script>

<style scoped>
.toast-section {
  position: fixed;
  inset: auto 50% 1.5rem 50%;
  width: fit-content;
  min-width: 26rem;
  max-width: 35rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transform: translateX(-50%);

  > .toast {
    @mixin text-lg var(--font-weight-semibold);

    border-radius: var(--base-border-radius-sm);
    padding: 1rem 1.5rem;
    color: var(--color-white);
    display: flex;
    align-items: center;
    gap: 1.5rem;
    box-shadow:
      0px 4px 24px 0px rgba(0, 0, 0, 0.25),
      0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    animation: fade-in-and-out 6000ms;

    @keyframes fade-in-and-out {
      0% {
        transform: translateY(20rem);
      }
      5% {
        transform: translateY(0);
      }
      95% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(20rem);
      }
    }

    &::before {
      content: '';
      width: 1.5rem;
      height: 1.5rem;
      display: block;
      background-color: var(--color-white);
      mask-repeat: no-repeat;
      mask-position: center center;
      mask-size: 100% 100%;
    }

    &.-danger {
      background-image: var(--gradient-danger);
      color: var(--color-neutral-900);

      &::before {
        background-color: var(--color-neutral-900);
        mask-image: url('/icons/x-circle.svg');
      }
    }

    &.-warning {
      background-color: #f4b62f;
      color: var(--color-neutral-900);

      &::before {
        background-color: var(--color-neutral-900);
        mask-image: url('/icons/alert.svg');
      }
    }

    &.-success {
      background-image: var(--gradient-success);
      color: var(--color-neutral-900);

      &::before {
        background-color: var(--color-neutral-900);
        mask-image: url('/icons/check-circle.svg');
      }
    }

    &.-notification {
      color: var(--color-white);
      background-color: var(--color-neutral-700);

      &::before {
        background-color: var(--color-white);
        mask-image: url('/icons/info.svg');
      }
    }
  }
}
</style>

<template>
  <div class="lucid-icon">
    <component
      :is="icon"
      class="svg"
      :size="size || 24"
      :color="color || '#ffffff'"
      :stroke-width="strokeWidth || 2"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, type Component } from 'vue';
import MenuArrowLeft from '~/common/custom-icons/MenuArrowLeft.vue';
import House from '~/common/custom-icons/House.vue';
import CustomMilestone from '~/common/custom-icons/CustomMilestone.vue';
import CustomTask from '~/common/custom-icons/CustomTask.vue';
import {
  Network,
  Users2,
  Film,
  LayoutDashboard,
  Gem,
  Paintbrush,
  Lightbulb,
  ToyBrick,
  SearchCode,
  Wallpaper,
  Milestone,
  FileText,
  PenTool,
  LifeBuoy,
  Menu,
  CalendarClock,
  Plus,
  CopyPlus,
  ArrowDownToLine,
  ExternalLink,
  Mail,
  Phone,
  Shell,
  DoorClosed,
  DoorOpen,
  Settings,
  Folder,
  XCircle,
  CheckCircle,
  HandMetal,
  AlertTriangle,
  HeartCrack,
  Quote,
  X,
  Pencil,
  Info,
  Calendar,
  PlusCircle,
  Undo,
  Redo,
  ArrowRight,
  ArrowLeft,
  Trash,
  MoreHorizontal,
  Folders
} from 'lucide-vue-next';
import type { Icon } from 'lucide-vue-next';

type IconName =
  | 'x'
  | 'folder'
  | 'house'
  | 'settings'
  | 'network'
  | 'film'
  | 'paintbrush'
  | 'lightbulb'
  | 'gem'
  | 'wallpaper'
  | 'milestone'
  | 'menu'
  | 'plus'
  | 'pencil'
  | 'copy-plus'
  | 'undo'
  | 'redo'
  | 'mail'
  | 'phone'
  | 'users-2'
  | 'menu-arrow-left'
  | 'toy-brick'
  | 'search-code'
  | 'pen-tool'
  | 'file-text'
  | 'layout-dashboard'
  | 'life-buoy'
  | 'calendar-clock'
  | 'arrow-down-to-line'
  | 'external-link'
  | 'door-closed'
  | 'x-circle'
  | 'check-circle'
  | 'door-open'
  | 'hand-metal'
  | 'alert-triangle'
  | 'heart-crack'
  | 'info'
  | 'trash'
  | 'calendar'
  | 'arrow-right'
  | 'arrow-left'
  | 'plus-circle'
  | 'more-horizontal'
  | 'email'
  | 'shell'
  | 'folders'
  | 'quote';

const props = defineProps<{
  name: IconName | string;
  color?: string;
  size?: number;
  strokeWidth?: number;
}>();

const icons: Record<string, Icon | Component> = {
  house: House,
  network: Network,
  film: Film,
  paintbrush: Paintbrush,
  lightbulb: Lightbulb,
  gem: Gem,
  wallpaper: Wallpaper,
  milestone: Milestone,
  menu: Menu,
  plus: Plus,
  mail: Mail,
  email: Mail,
  phone: Phone,
  shell: Shell,
  settings: Settings,
  folder: Folder,
  quote: Quote,
  info: Info,
  undo: Undo,
  redo: Redo,
  calendar: Calendar,
  trash: Trash,
  pencil: Pencil,
  x: X,
  'more-horizontal': MoreHorizontal,
  'arrow-right': ArrowRight,
  'arrow-left': ArrowLeft,
  'copy-plus': CopyPlus,
  'x-circle': XCircle,
  'users-2': Users2,
  'menu-arrow-left': MenuArrowLeft,
  'toy-brick': ToyBrick,
  'search-code': SearchCode,
  'pen-tool': PenTool,
  'file-text': FileText,
  'layout-dashboard': LayoutDashboard,
  'life-buoy': LifeBuoy,
  'calendar-clock': CalendarClock,
  'arrow-down-to-line': ArrowDownToLine,
  'external-link': ExternalLink,
  'door-closed': DoorClosed,
  'door-open': DoorOpen,
  'check-circle': CheckCircle,
  'custom-milestone': CustomMilestone,
  'custom-task': CustomTask,
  'hand-metal': HandMetal,
  'alert-triangle': AlertTriangle,
  'heart-crack': HeartCrack,
  'plus-circle': PlusCircle,
  folders: Folders
};

const icon = computed(() => icons[props.name] ?? icons['network']);
</script>

<style scoped></style>

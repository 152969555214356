export default {
  general: {
    hello: 'hallo welt',
    save: 'Speichern',
    saved: 'Gespeichert',
    login: 'Einloggen',
    logout: 'Abmelden',
    username: 'Benutzername',
    password: 'Passwort',
    email: 'E-Mail',
    yes: 'ja',
    no: 'nein',
    documentSaved: 'Dokument wurde gespeichert',
    documentCreated: 'Dokument wurde erstellt'
  },
  formInputs: {
    file: {
      noneFileSelected: 'Kein File ausgewählt',
      selectFromLibrary: 'Bild von Library auswählen',
      uploadImage: 'Bild hochladen (max. 2MB)'
    }
  },
  login: {
    title: 'Willkommen im Gridonic Portal',
    subtitle: 'Die Plattform für unsere Kundenprojekte.',
    form: {
      title: 'Login',
      submit: 'Login',
      info: 'Fragen zur Anmeldung bitte an portal@gridonic.ch.'
    }
  },
  forgotPassword: {
    form: {
      title: 'E-Mail Adresse zum Zurücksetzen des Passworts eingeben',
      submit: 'Passwort zurücksetzen',
      info: 'Fragen zur Anmeldung bitte an portal@gridonic.ch.',
      successMessage:
        'Dein Passwort wurde erfolgreich zurückgesetzt.' +
        ' Es wurde eine E-Mail verschickt mit einem Link zum Ändern des Passworts.'
    }
  },
  resetPassword: {
    form: {
      title: 'Hier kannst du ein neues Passwort definieren',
      submit: 'Passwort setzen',
      labelPassword: 'Neues Passwort',
      info: 'Fragen zur Anmeldung bitte an portal@gridonic.ch.',
      successMessage: 'Dein Passwort wurde erfolgreich geändert.'
    },
    errorMessages: {
      unknown: 'Ein unbekannter Fehler ist aufgetreten.',
      token: 'Link ungültig.',
      password: 'Passwort entspricht nicht unseren Kriterien. '
    }
  },
  admin: {
    projects: {
      titleContainer: {
        title: 'Projektübersicht'
      },
      formCreateProject: {
        title: 'Neues Projekt erstellen',
        submit: 'Projekt erstellen'
      }
    },
    users: {
      titleContainer: {
        eyebrow: '',
        title: 'User',
        subtitle: 'Liste aller aktiven Gridonic Portal User.'
      },
      formCreateUser: {
        title: 'Neuer User erstellen',
        submit: 'User erstellen'
      }
    },
    settingsGeneral: {
      formGeneral: {
        title: 'Allgemeine Einstellungen',
        info: 'Als erstes definieren wir die Grundeinstellungen für das Projekt. Welche User haben Zugriff, welche Tools sollen freigeschaltet und welche Widgets im Dashboard angezeigt werden.'
      },
      formAsanaConfig: {
        title: 'Konfiguration',
        info: 'Um dein Projekt mit Asana verbinden zu können, musst du als erstes ein neues Team in Asana erstellen. Dieses kannst du dann hier entsprechend auswählen. Vergiss nicht, deine Einstellungen zu speichern.'
      },
      formCache: {
        title: 'Cache',
        info: 'Asana und Harvest API Requests werden für 30 Minuten gecached, um die Performance zu verbessern. Wenn du zu ungeduldig bist, kannst du hier den Cache manuell leeren, um neue Daten zu holen.'
      },
      formDeleteProject: {
        title: 'Danger area',
        button: 'Dieses Projekt löschen?',
        modalText: 'Diese Aktion ist nicht widerrufbar',
        modalButton: 'Projekt endgültig löschen'
      }
    },
    settingsDashboard: {
      title: 'Dashboard Einstellungen',
      info: 'Hier können alle Tools & Widgets, die mit Asana und Harvest zusammenhängen, konfiguriert werden. Ausserdem kann hier eine Kontaktperson erfasst werden, die auf dem Dashboard angezeigt wird.',
      completeAsanaConfiguration:
        'Asana Konfiguration unter Allg. Einstellungen vornehmen.',
      titleInputStatus: 'Projektstatus',
      descriptionInputStatus:
        'In diesem Widget wird der Projektstatus angezeigt. Die Infos dazu werden von den Status-Updates in der "Overview" des Planning Boards gezogen.',
      titleInputResources: 'Ressourcen',
      descriptionInputResources:
        'In diesem Widget werden alle Dateien angezeigt, die in der "Overview" des Planning Boards im Bereich "Key resources" hochgeladen werden.',
      titleInputTeam: 'Team',
      descriptionInputTeam:
        'Dieses Widget stellt das Projektteam dar. Die Infos dazu holen wir aus dem Bereich "Project Roles" in der Übersicht des Planning Boards.',
      titleInputMilestones: 'Milestones',
      descriptionInputMilestones:
        'Hier stellen wir max. 5 bevorstehende Meilensteine des Projekts dar. Diese Information werden aus dem Bereich "Milestones" in der Übersicht des Planning Boards gezogen. ',
      titleInputTasks: 'Tasks',
      descriptionInputTasks:
        'Dieses Widget zeigt max. 10 offene Tasks aus dem Planning Board an. Die Informationen dazu holen wir aus dem Bereich "Tasks" in der Listen-Übersicht.',
      titleInputAdmin: 'Admin',
      descriptionInputAdmin: '---Wird aktuell nicht verwendet---',
      titleInputLinks: 'Links und Zugänge',
      descriptionInputLinks:
        'Dieses Widget zeigt alle wichtigen Links & Zugänge für das Projekt. Die Informationen dazu findest du im entsprechenden Ticket im Bereich "Admin" der Listen-Übersicht.',
      titleInputAppointmentLink: '"Besprechungstermin buchen" Link',
      titleInputInvoices: 'Rechnungen',
      descriptionInputInvoices:
        'Hier kannst du das Harvest-Projekt verbinden, um alle Rechnungen anzuzeigen. Wir zeigen alle Rechnungen an, unabhängig ob bereits bezahlt oder noch offen. Wähle dazu den Kunden und das entsprechende Projekt aus.'
    },
    settingsManual: {
      title: 'Tutorial Einstellungen',
      info: 'Hier kannst du die Videos auswählen, die den Kunden unter Tutorials angezeigt werden sollen.'
    },
    settingsProducts: {
      title: 'Products Einstellungen',
      info: 'Hier kannst du die Produkte auswählen, die den Kunden im Tool Produkte angezeigt werden sollen.'
    },
    settingsAnimations: {
      title: 'Animations Einstellungen',
      info: 'Hier kannst du Animationen dem Kunden zugänglich machen. Wähle dazu die Animationen aus, welche für das neue Design angedacht sind.'
    },
    settingsBriefing: {
      title: 'Briefing Einstellungen',
      info: 'Wähle hier die Fragen für neu erstelle Briefing Dokumente aus'
    },
    settingsVisualBriefing: {
      title: 'Visual Briefing Einstellungen',
      info: 'Wähle hier einerseits die Key-Value-Pairs aus, die der User bei der Evaluation ausfüllen muss und die Webseiten, die der User bewerten muss. Die gespeicherten Einstellungen sind für neu erstellte Visual-Briefing-Dokumente wirksam.',
      infoKeys: 'Die ausgewählten Key-Value-Pairs muss der User evaluieren.',
      infoWebsites: 'Die ausgewählten Webseiten muss der User bewerten'
    },
    settingsScreendesigns: {
      formConfig: {
        title: 'Screen Design Einstellungen',
        info: 'Um die enstprechenden Figma-Files anzeigen zu können, musst zu zuerst die Figma Team ID eingeben. Die Team ID findest du in der URL, wenn du in Figma auf der Team-Übersicht auf "Share" und "Copy Link" klickst.'
      },
      formFiles: {
        title: 'Screen Designs auswählen',
        info: 'Gib zuerst das Projekt an, bevor du die verfügbaren Screendesigns auswählen kannst.'
      }
    },
    settingsOffering: {
      title: 'Offering Einstellungen',
      info: 'Du kannst hier alle Einstellungen für das Offering Tool vornehmen.',
      document: {
        title: 'Einstellung für Offering Dokumente',
        info: 'Hier kann das Offering-Tool Projekt-Ebene konfiguriert werden.'
      },
      pdf: {
        title: 'Einstellungen für PDF-Offerte',
        info: 'Alles, was im PDF generiert wird, muss hier erfasst werden.'
      }
    },
    settingsProcedure: {
      title: 'Vorgehen Einstellungen',
      info: 'Du kannst hier alle Einstellungen für die Vorgehens Seite vornehmen',
      setInitialSteps: 'Initiale Steps erstellen',
      createStep: {
        title: 'Neuen Step erstellen',
        submitText: 'Step erstellen',
        fileInputLabel: 'Bild hochladen (max. 2 MB)',
        titleLabel: 'Titel',
        textLabel: 'Text',
        imageLabel: 'Bild'
      },
      updateStep: {
        title: 'Step bearbeiten',
        submitText: 'speichern',
        titleLabel: 'Titel',
        textLabel: 'Text',
        fileInputLabel: 'Bild hochladen (max. 2 MB)',
        imageLabel: 'Bild',
        deleteStep: 'Step löschen'
      }
    }
  },
  project: {
    overview: {
      titleContainer: {
        title: 'Projektübersicht',
        subtitle: ''
      }
    },
    dashboard: {
      titleContainer: {
        title: 'Dashboard',
        subtitle:
          'Hier finden Sie die wichtigsten Informationen rund um Ihr Projekt.'
      },
      widgets: {
        cardScreenDesigns: 'Screen Designs',
        cardIATool: 'Information Architecture',
        cardOfferingTool: 'Offering',
        cardProcedureTool: 'Vorgehen',
        cardManualTool: 'Tutorials',
        cardAnimationsTool: 'Animations',
        cardAppointmentLink: 'Termin buchen',
        linksAndAccesses: {
          title: 'Inhalte',
          error: 'Keine Beschreibung verfügbar.'
        },
        team: {
          title: 'Team'
        },
        contactPerson: {
          title: 'Kontaktperson',
          info: 'Kontaktperson Info...' // @alice würde es hier auch noch error und nonAvailable benötigen?
        },
        resources: {
          title: 'Resources (Asana)',
          error: 'Die Dokumente (Asana) konnten nicht geladen werden.',
          noneAvailable: 'Es sind noch keine Asana-Dokumente hinterlegt.'
        },
        documents: {
          title: 'Dokumente',
          error: 'Die Dokumente konnten nicht geladen werden.',
          noneAvailable: 'Es sind noch keine Dokumente hinterlegt.'
        },
        milestones: {
          title: 'Milestones',
          error: 'Die Milestones konnten nicht geladen werden.',
          noneAvailable: 'Es wurden noch keine Milestones definiert.'
        },
        asanaTasks: {
          title: 'Tasks',
          error: 'Tasks konnten nicht geladen werden.',
          noneAvailable: 'Es gibt aktuell keine offenen Tasks.'
        },
        invoices: {
          title: 'Rechnungen',
          error: 'Die Rechnungen konnten nicht geladen werden.',
          noneAvailable: 'Es wurden noch keine Rechnungen erfasst.',
          number: 'Rechnung',
          dueOn: 'Fällig:'
        },
        status: {
          title: 'Status',
          error: 'Status konnte nicht geladen werden.' // to do @alice können wir hier auch noch nonAvailable ergänzen?
        },
        tasks: {
          title: 'Next Steps',
          error: 'Tasks konnten nicht geladen werden.',
          noneAvailable: 'Es wurden noch keine Tasks erfasst.'
        }
      }
    }
  },
  tool: {
    manual: {
      titleContainer: {
        eyebrow: '',
        title: 'Tutorials',
        subtitle: {
          0: 'Bisher wurde keine Videos verlinkt.',
          n: 'Hier finden Sie eine Auswahl an Tutorials, in denen unser Team die wichtigsten Handgriffe erklärt.'
        }
      }
    },
    animations: {
      titleContainer: {
        eyebrow: '',
        title: 'Animations',
        subtitle: {
          0: 'Bisher wurde keine Animations verlinkt.',
          n: 'Hier finden Sie eine Auswahl an Animations, die wir uns für Ihr Projekt gut vorstellen können.'
        }
      },
      imageAttachments: {
        title: 'Bilder'
      },
      downloadAttachments: {
        title: 'Weitere Assets herunterladen'
      },
      isLoading: 'Lädt Animationen...'
    },
    briefing: {
      titleContainer: {
        lead: 'Das Briefing Tool hilft ein Briefing zu erstellen. Sie werden durch die einzelnen Schritte geleitet.',
        title: 'Briefing'
      },
      isLoading: 'Lädt Briefing Dokumente...',
      createNewDocument: {
        title: 'Briefing Tool starten',
        labelName: 'Dokumenttitel',
        submit: 'erstellen'
      }
    },
    visualBriefing: {
      titleBanner: {
        title: 'Hi, Willkommen beim Gridonic Visual Design Tool',
        lead: 'Das Visual Design Tool hilft erste Designrichtungen zu identifizieren. Sie werden durch die einzelnen Schritte geleitet.'
      },
      isLoading: 'Lädt Dokumente...',
      createNewDocument: {
        title: 'Visual Design Tool starten',
        labelName: 'Dokumenttitel',
        submit: 'erstellen'
      },
      document: {
        buttons: {
          continue: 'Weiter',
          finish: 'Abschliessen',
          goBack: 'Zurück'
        },
        evaluations: {
          title: 'Schätzen Sie Ihr Unternehmen ein.',
          subtitle: 'Es müssen alle Skalen bewertet werden.'
        },
        websiteRating: {
          title: 'Klicken Sie sich durch die Websites und bewerten Sie sie.',
          subtitle: 'Es müssen alle Websites bewertet werden.',
          titleRating: 'Bewertung',
          titlePositiveTags: 'Welche Dinge sind am positivsten aufgefallen?',
          titleNotes: 'Gibt es Anmerkungen?',
          placeholderNotes: 'Anmerkung schreiben'
        },
        inspiration: {
          title: 'Bitte laden Sie hier Ihre Inspirationen hoch',
          subtitle:
            'Das können Bilder, Websites, Farben, Illustrationsstile etc. sein',
          infoTitle:
            'Das können Bilder, Websites, Farben, Illustrationsstile etc. sein',
          createItem: {
            title: 'Inspiration hinzufügen',
            submitText: 'Hinzufügen',
            labelUrl: 'Website-URL*',
            labelComment: 'Kommentar',
            labelImages: 'Bilder / Screenshots (max. 20MB)*',
            info: 'Lade hier deine Inspiration hoch.',
            success: 'Inspiration wurde hinzugefügt'
          },
          updateItem: {
            labelUrl: 'Website-URL',
            labelComment: 'Kommentar',
            labelImages: 'Bilder / Screenshots (max. 20MB)'
          }
        }
      }
    },
    screendesigns: {
      titleContainer: {
        eyebrow: '',
        title: 'Screen Designs',
        subtitle: {
          0: 'Bisher wurden keine Screen Designs verlinkt.',
          1: 'Hier siehst du das Screen Design.',
          n: 'Hier siehst du Screen Designs.'
        }
      }
    },
    integrations: {
      titleBanner: {
        title:
          'Hier findest du Erklärungen von Integrationen für dein digitales Produkt',
        lead: 'Ausgewählte Integrationen werden direkt im Offering angezeigt.'
      },
      isLoading: 'Lädt...'
    },
    tipps: {
      titleContainer: {
        title: 'Tipps',
        subtitle: 'Hier findest du nützliche Tipps rund um digitale Produkte.'
      },
      isLoading: 'Lädt Tipps...',
      readTip: 'Tipp lesen'
    },
    offering: {
      titleContainer: {
        eyebrow: '',
        title: 'Offering',
        subtitle: 'Hier finden Sie Ihre Offering Dokumente.'
      },
      isLoadingDocuments: 'Lädt Dokumente...',
      teaser: {
        name: 'Name',
        descriptionPlaceholder: 'Notiz...',
        description: 'Beschreibung',
        delete: 'Dokument löschen',
        open: 'Dokument öffnen',
        downloadPdf: 'PDF herunterladen'
      },
      formCreateDocument: {
        title: 'Neues Offering-Dokument erstellen',
        submitText: 'Dokument erstellen',
        inputs: {
          title: 'Titel',
          titlePlaceholder: 'Titel...',
          options: 'Offering Typ'
        }
      },
      document: {
        isLoading: 'Lädt Dokument...',
        loadPreConfig: 'Vordefinierte Konfiguration Laden',
        reset: 'Zurücksetzen',
        footer: {
          countElements: 'Anzahl Elemente',
          costs: 'Kosten',
          saveButton: {
            text: 'Auswahl speichern',
            successText: 'Auswahl gespeichert'
          }
        },
        integrationSection: {
          title: 'Integrationen',
          description: '',
          noIntegrationsSelected: 'Keine Integrationen vorhanden',
          loadingSelectedIntegrations: 'Lädt Daten...'
        }
      },
      types: {
        lean: 'Lean',
        launch: 'Launch',
        custom: 'Custom',
        experience: 'Experience',
        animation: 'Animation',
        commerce: 'Commerce',
        tech: 'Tech',
        'custom-1': 'Custom',
        'custom-2': 'Custom',
        'low-code': 'Low Code',
        'tech-only': 'Tech Only',
        'low-code-only': 'Low Code Only'
      }
    },
    ia: {
      titleContainer: {
        eyebrow: '',
        title: 'Informationsarchitektur',
        subtitle:
          'Hier finden Sie die Informationsarchitekturen Ihrer Webseite.'
      },
      formCreate: {
        title: 'Neue Informations-Architektur erstellen',
        submit: 'IA erstellen',
        error: 'IA konnte nicht erstellt werden.',
        titlePlaceholder: 'Dein Beschrieb...',
        titleLabel: ''
      },
      cardsView: {
        title: 'Brainstorming',
        toggle: 'Cards'
      },
      treeView: {
        title: 'Informationsarchitektur',
        toggle: 'Tree'
      }
    },
    techEvaluation: {
      titleContainer: {
        eyebrow: '',
        title: '',
        subtitle: ''
      }
    },
    lookAndFel: {
      titleContainer: {
        eyebrow: '',
        title: '',
        subtitle: ''
      }
    },
    procedure: {
      titleContainer: {
        title: 'Ein Vorgehen in 10 Schritten'
      },
      card: {
        action: 'Asana Link'
      }
    },
    ticketing: {
      titleContainer: {
        title: 'Ticketing'
      }
    },
    products: {
      titleContainer: {
        eyebrow: '',
        title: 'Gridonic Products',
        subtitle: 'Hier finden Sie unsere Produkte.'
      }
    }
  }
};

import { AppDataDto } from '@common/app.dtos';
import { computed, ComputedRef } from 'vue';
import { UserDto } from '@common/user/user.dtos';
import { injectionKey } from '~/common/utils/inject';

export const AppDataInjectionKey = injectionKey<{
  data: AppDataDto;
  isAdmin: ComputedRef<boolean>;
  locale: string | undefined;
  currentUser: ComputedRef<UserDto | null>;
  version: ComputedRef<string>;
}>();

export function useAppData(el: HTMLElement) {
  const data: AppDataDto = JSON.parse(el.getAttribute('data-app') ?? '{}');

  const locale = data.currentUser?.locale;
  const isAdmin = computed((): boolean => data.currentUser?.role === 'admin');
  const currentUser = computed((): UserDto | null => data.currentUser || null);
  const version = computed(() => data.version);

  return {
    data,
    locale,
    isAdmin,
    currentUser,
    version
  };
}

<template>
  <div class="app">
    <component :is="layout" v-if="!isLoading" class="layout" :class="appStyle">
      <RouterView :key="route.path" />
    </component>
    <ToastSection />
  </div>
</template>

<script setup lang="ts">
import DefaultLayout from '@/layout/Default.vue';
import AuthLayout from '@/layout/Auth.vue';
import NoneLayout from '@/layout/None.vue';
import BlankLayout from '@/layout/Blank.vue';
import { type Component, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useTabs from '~/common/layout/useTabs';
import useNavigation from '~/tool/ia/useNaviagtion';
import useProjects from '~/project/useProjects';
import ToastSection from '~/notifications/ToastSection.vue';

const router = useRouter();
const route = useRoute();

useTabs();
useNavigation();

const { isLoading } = useProjects();

// variables
const layoutComponents: { [key: string]: Component } = {
  default: DefaultLayout,
  auth: AuthLayout,
  blank: BlankLayout,
  none: NoneLayout
};

// computed
const layout = computed(() => {
  const layoutName: string = (route.meta.layout as string) || 'default';
  return layoutComponents[layoutName];
});

const appStyle = computed((): string => {
  return (route.meta.appStyle as string) || '';
});
</script>

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/styleguide',
    name: 'Styleguide',
    component: () => import('../styleguide/Styleguide.vue'),
    meta: {
      layout: 'blank',
      appStyle: '',
      access: '',
      isHomeRoute: true
    }
  },
  // auth
  {
    path: '/login',
    name: 'Login',
    component: () => import('../user/Login.vue'),
    meta: {
      layout: 'auth',
      appStyle: '',
      access: '',
      isHomeRoute: true
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('../user/ResetPassword.vue'),
    props: (route) => ({
      token: route.params.token
    }),
    meta: {
      layout: 'auth',
      appStyle: '',
      access: '',
      isHomeRoute: true
    }
  },
  // admin
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../admin/Entry.vue'),
    meta: {
      appStyle: '',
      layout: 'default',
      access: 'admin',
      isHomeRoute: true
    },
    children: [
      {
        path: 'projects',
        name: 'AdminProjects',
        component: () => import('../admin/Projects.vue'),
        meta: {
          name: 'Projektübersicht',
          icon: 'folders',
          appStyle: '-watermark'
        }
      },
      {
        path: 'users',
        name: 'AdminUsers',
        component: () => import('../admin/Users.vue'),
        meta: {
          name: 'Userübersicht',
          icon: 'users-2'
        }
      }
    ]
  },
  {
    path: '/admin/projects/:projectId/settings',
    name: 'AdminProjectSettings',
    component: () => import('../admin/projectsettings/Entry.vue'),
    meta: {
      appStyle: '',
      layout: 'default',
      access: 'admin',
      isHomeRoute: true
    },
    props: (route) => ({
      projectId: route.params.projectId
    }),
    children: [
      {
        path: 'dashboard',
        name: 'AdminProjectSettingsDashboard',
        component: () => import('../admin/projectsettings/Dashboard.vue'),
        meta: {
          name: 'Dashboard Einstellungen',
          icon: 'layout-dashboard'
        }
      },
      {
        path: 'general',
        name: 'AdminProjectSettingsGeneral',
        component: () => import('../admin/projectsettings/General.vue'),
        meta: {
          name: 'Allg. Einstellungen',
          icon: 'layout-dashboard'
        }
      },
      {
        path: 'manual',
        name: 'AdminProjectSettingsManual',
        component: () => import('../admin/projectsettings/Manual.vue'),
        meta: {
          name: 'Tutorial Einstellungen',
          icon: 'film'
        }
      },
      {
        path: 'products',
        name: 'AdminProjectSettingsProducts',
        component: () => import('../admin/projectsettings/Products.vue'),
        meta: {
          name: 'Products Einstellungen',
          icon: 'toy-brick'
        }
      },
      {
        path: 'animations',
        name: 'AdminProjectSettingsAnimations',
        component: () => import('../admin/projectsettings/Animations.vue'),
        meta: {
          name: 'Animations Einstellungen',
          icon: 'shell'
        }
      },
      {
        path: 'briefing',
        name: 'AdminProjectSettingsBriefing',
        component: () => import('../admin/projectsettings/Briefing.vue'),
        meta: {
          name: 'Briefing Einstellungen',
          icon: 'file-text'
        }
      },
      {
        path: 'visual-briefing',
        name: 'AdminProjectSettingsVisualBriefing',
        component: () => import('../admin/projectsettings/VisualBriefing.vue'),
        meta: {
          name: 'Visual Briefing Einstellungen',
          icon: 'paintbrush'
        }
      },
      {
        path: 'screendesign',
        name: 'AdminProjectSettingsScreendesign',
        component: () => import('../admin/projectsettings/Screendesign.vue'),
        meta: {
          name: 'Screen Design Einstellungen',
          icon: 'wallpaper'
        }
      },
      {
        path: 'offering',
        name: 'AdminProjectSettingsOffering',
        component: () => import('../admin/projectsettings/Offering.vue'),
        meta: {
          name: 'Offering Einstellungen',
          icon: 'gem'
        }
      },
      {
        path: 'procedure',
        name: 'AdminProjectSettingsProcedure',
        component: () => import('../admin/projectsettings/Procedure.vue'),
        meta: {
          name: 'Vorgehen Einstellungen',
          icon: 'milestone'
        }
      },
      {
        path: 'timeline',
        name: 'AdminProjectSettingsTimeline',
        component: () => import('../admin/projectsettings/Timeline.vue'),
        meta: {
          name: 'Timeline Einstellungen',
          icon: 'milestone'
        }
      },
      {
        path: 'tasks',
        name: 'AdminProjectSettingsTasks',
        component: () => import('../admin/projectsettings/Tasks.vue'),
        meta: {
          name: 'Tasks Einstellungen',
          icon: 'milestone'
        }
      }
    ]
  },
  // project
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../project/Entry.vue'),
    meta: {
      appStyle: '',
      layout: 'default',
      access: 'user',
      isHomeRoute: true
    },
    children: [
      {
        path: 'overview',
        name: 'ProjectOverview',
        component: () => import('../project/Overview.vue'),
        meta: {
          appStyle: '',
          name: 'Übersicht Projekte',
          icon: 'default'
        }
      }
    ]
  },
  // project detail
  {
    path: '/projects/:projectId',
    name: 'ProjectDetail',
    component: () => import('../project/detail/Entry.vue'),
    props: (route) => ({
      projectId: route.params.projectId
    }),
    meta: {
      appStyle: '',
      layout: 'default',
      access: 'user',
      isHomeRoute: true
    },
    children: [
      {
        path: 'dashboard',
        name: 'ProjectDashboard',
        component: () => import('../project/detail/Dashboard.vue'),
        meta: {
          name: 'Dashboard',
          icon: 'layout-dashboard',
          appStyle: '-watermark'
        }
      },
      {
        path: 'offering',
        name: 'OfferingTool',
        component: () => import('../tool/offering/Entry.vue'),
        meta: {
          name: 'Offering',
          icon: 'gem',
          appStyle: '-watermark'
        }
      },
      {
        path: 'ia',
        name: 'IaTool',
        component: () => import('../tool/ia/Entry.vue'),
        meta: {
          name: 'Informationsarchitektur',
          icon: 'network',
          appStyle: '-watermark'
        }
      },
      {
        path: 'look-and-feel',
        name: 'LookandfeelTool',
        component: () => import('../tool/look-and-feel/Entry.vue'),
        meta: {
          name: 'Look and feel',
          icon: 'paintbrush'
        }
      },
      {
        path: 'manual',
        component: () => import('../tool/manual/Entry.vue'),
        name: 'ManualTool',
        meta: {
          name: 'Tutorials',
          icon: 'film'
        }
      },
      {
        path: 'products',
        component: () => import('../tool/products/Entry.vue'),
        name: 'ProductsTool',
        meta: {
          name: 'Gridonic Products',
          icon: 'toy-brick'
        }
      },
      {
        path: 'animations',
        component: () => import('../tool/animations/Entry.vue'),
        name: 'AnimationsTool',
        meta: {
          name: 'Animations',
          icon: 'shell'
        }
      },
      {
        path: 'briefing',
        component: () => import('../tool/briefing/Entry.vue'),
        name: 'BriefingTool',
        meta: {
          name: 'Briefing',
          icon: 'file-text',
          appStyle: '-watermark'
        }
      },
      {
        path: 'visual-briefing',
        component: () => import('../tool/visual-briefing/Entry.vue'),
        name: 'VisualBriefingTool',
        meta: {
          name: 'Visual Briefing',
          icon: 'pen-tool',
          appStyle: '-watermark'
        }
      },
      {
        path: 'screen-design',
        component: () => import('../tool/screen-design/Entry.vue'),
        name: 'ScreendesignTool',
        meta: {
          name: 'Screen Designs',
          icon: 'wallpaper'
        }
      },
      {
        path: 'tech-evaluation',
        component: () => import('../tool/tech-evaluation/Entry.vue'),
        name: 'TechevaluationTool',
        meta: {
          name: 'Tech Evaluation',
          icon: 'search-code'
        }
      },
      {
        path: 'procedure',
        component: () => import('../tool/procedure/Entry.vue'),
        name: 'ProcedureTool',
        meta: {
          name: 'Unser Vorgehen',
          icon: 'milestone'
        }
      },
      {
        path: 'integrations',
        component: () => import('../tool/integrations/Entry.vue'),
        name: 'IntegrationsTool',
        meta: {
          name: 'Integrations (Offering)',
          icon: 'toy-brick'
        }
      },
      {
        path: 'tipps',
        component: () => import('../tool/tipps/Entry.vue'),
        name: 'TippsTool',
        meta: {
          name: 'Tipps',
          icon: 'lightbulb',
          appStyle: '-watermark'
        }
      }
    ]
  },
  // ia document
  {
    path: '/projects/:projectId/ia/:documentId',
    name: 'IaToolDocument',
    component: () => import('../tool/ia/Document.vue'),
    props: (route) => ({
      projectId: route.params.projectId,
      documentId: route.params.documentId
    }),
    meta: {
      name: 'IA Dokument',
      icon: 'network',
      appStyle: '',
      layout: 'blank',
      access: 'user'
    }
  },
  // briefing document
  {
    path: '/projects/:projectId/briefing/:documentId',
    name: 'BriefingDocument',
    component: () => import('../tool/briefing/Document.vue'),
    props: (route) => ({
      projectId: route.params.projectId,
      documentId: route.params.documentId
    }),
    meta: {
      name: 'Briefing Dokument',
      icon: 'file-text',
      appStyle: '',
      layout: 'blank',
      access: 'user'
    }
  },
  // visual briefing document
  {
    path: '/projects/:projectId/visual-briefing/:documentId',
    name: 'VisualBriefingDocument',
    component: () => import('../tool/visual-briefing/Document.vue'),
    props: (route) => ({
      projectId: route.params.projectId,
      documentId: route.params.documentId
    }),
    meta: {
      name: 'Visual Briefing Dokument',
      icon: 'paintbrush',
      appStyle: '',
      layout: 'blank',
      access: 'user'
    }
  },
  // tipps document
  {
    path: '/projects/:projectId/tipps/:documentId',
    name: 'TippsDocument',
    component: () => import('../tool/tipps/Document.vue'),
    props: (route) => ({
      projectId: route.params.projectId,
      documentId: route.params.documentId
    }),
    meta: {
      name: 'Tipps Dokument',
      icon: 'lightbulb',
      appStyle: '',
      layout: 'blank',
      access: 'user'
    }
  },
  // offering document
  {
    path: '/projects/:projectId/offering/:documentId',
    name: 'OfferingToolDocument',
    component: () => import('../tool/offering/Document.vue'),
    props: (route) => ({
      projectId: route.params.projectId,
      documentId: route.params.documentId
    }),
    meta: {
      name: 'Offering Dokument',
      icon: 'gem',
      appStyle: '',
      layout: 'blank',
      access: 'user'
    }
  },
  {
    path: '/projects/:projectId/offering/:documentId/pdf',
    name: 'OfferingPDF',
    component: () => import('../tool/offering/PDF.vue'),
    props: (route) => ({
      projectId: route.params.projectId,
      documentId: route.params.documentId
    }),
    meta: {
      name: 'Offering Dokument',
      icon: 'gem',
      appStyle: '',
      layout: 'none',
      access: 'user'
    }
  }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes
});

export default router;

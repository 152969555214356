import { computed } from 'vue';
import { useCookies } from '@vueuse/integrations/useCookies';

const cookies = useCookies(['Csrf']);

export default function useCsrf() {
  return {
    token: computed(() => cookies.get('Csrf'))
  };
}

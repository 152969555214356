import { CreateUserDto, UpdateUserDto, UserDto } from '@common/user/user.dtos';
import { ProjectDto } from '@common/project/project.dtos';
import useApi from '~/common/utils/useApi';

export default function useUserRepository() {
  function getClients() {
    return useApi<UserDto[]>('user/clients').get();
  }

  function createUser(user: CreateUserDto) {
    return useApi<UserDto>('user/create').post({ body: user });
  }

  function updateUser(id: number, updates: Partial<UpdateUserDto>) {
    return useApi<UserDto>(`user/${id}/update`).put({ body: updates });
  }

  function removeUser(id: number) {
    return useApi(`user/${id}`).delete();
  }

  function getProjects() {
    return useApi<ProjectDto[]>('user/projects').get();
  }

  return {
    getClients,
    createUser,
    updateUser,
    removeUser,
    getProjects
  };
}

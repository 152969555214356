export default {
  message: {
    hello: 'hello world',
    save: 'save',
    imprint: 'imprint',
    dataPrivacy: 'data privacy',
    login: 'login',
    logout: 'logout',
    yes: 'yes',
    no: 'no'
  }
};

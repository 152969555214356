<template>
  <div class="app-layout -none">
    <main class="main">
      <slot />
    </main>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.app-layout {
  background-color: var(--color-white);
  color: var(--color-black);
}
</style>

import {
  ProjectDto,
  ProjectSettingsDto,
  ProjectStatusDto
} from '@common/project/project.dtos';
import { HarvestInvoicesDto } from '@common/harvest.dtos';
import {
  AsanaAttachmentDto,
  AsanaTaskDto,
  AsanaTeammemberDto
} from '@common/asana.dtos';
import { ContactPersonDto } from '@common/contact-person.dtos';
import { AnimationDto } from '@common/animations.dtos';
import useApi from '~/common/utils/useApi';
import { ProductItemAirtable } from '@common/products.dtos';

export default function useProjectRepository() {
  async function fetchDefaultSettings() {
    const api = useApi<ProjectSettingsDto>('project/default-settings');
    return api.get();
  }

  function getProject() {
    const api = useApi<ProjectDto[]>('user/projects');
    return api.get();
  }

  function getAllProjects() {
    const api = useApi<ProjectDto[]>('project/all');
    return api.get();
  }

  async function createProject(body: ProjectSettingsDto) {
    const api = useApi<ProjectDto[]>('project/create');
    return api.post({ body });
  }

  async function removeProject(id: number | string) {
    const api = useApi<ProjectDto[]>(`project/${id}`);
    return api.delete();
  }

  function withProject(projectId: string | number) {
    function getById() {
      const api = useApi<ProjectDto>(`project/get/${projectId}`);
      return api.get();
    }

    function getStatus() {
      const api = useApi<ProjectStatusDto>(`project/${projectId}/status`);
      return api.get();
    }

    function getInvoices() {
      const api = useApi<HarvestInvoicesDto[]>(`project/${projectId}/invoices`);
      return api.get();
    }

    function getTeammembers() {
      const api = useApi<AsanaTeammemberDto[]>(
        `project/${projectId}/teammembers`
      );
      return api.get();
    }

    function getContactPerson() {
      const api = useApi<ContactPersonDto>(
        `project/${projectId}/contact-person`
      );
      return api.get();
    }

    function getResources() {
      const api = useApi<
        (AsanaAttachmentDto | { name: 'offeringLink'; download_url: string })[]
      >(`project/${projectId}/resources`);
      return api.get();
    }

    function getAsanaTasks() {
      const api = useApi<AsanaTaskDto[]>(`project/${projectId}/tasks`);
      return api.get();
    }
    function getDocuments() {
      const api = useApi<{ name: string; download_url: string }[]>(
        `project/${projectId}/documents`
      );
      return api.get();
    }

    function getMilestones() {
      const api = useApi<AsanaTaskDto[]>(`project/${projectId}/milestones`);
      return api.get();
    }

    function getLinksAndAccesses() {
      const api = useApi<AsanaTaskDto | null>(
        `project/${projectId}/links-and-accesses`
      );
      return api.get();
    }

    function getAppointmentLink() {
      const api = useApi<{ link: string }>(
        `project/${projectId}/appointment-link`
      );
      return api.get();
    }

    function getVideosIds() {
      const api = useApi<string[]>(`project/${projectId}/videos`);
      return api.get();
    }

    function getProducts() {
      const api = useApi<ProductItemAirtable[]>(
        `project/${projectId}/products`
      );

      return api.get();
    }

    function getAnimations() {
      const api = useApi<AnimationDto[]>(`project/${projectId}/animations`);
      return api.get();
    }

    function getScreendesigns() {
      const api = useApi<string[]>(`project/${projectId}/screendesigns`);
      return api.get();
    }

    function updateSettings(settings: ProjectSettingsDto) {
      const api = useApi<ProjectDto[]>(`project/${projectId}/update`);
      return api.put({ body: settings });
    }

    function remove() {
      const api = useApi<ProjectDto[]>(`project/${projectId}`);
      return api.delete();
    }

    function getSettings() {
      const api = useApi<ProjectSettingsDto>(`project/${projectId}/settings`);
      return api.get();
    }

    return {
      getById,
      getStatus,
      getInvoices,
      getTeammembers,
      getVideosIds,
      getAnimations,
      updateSettings,
      remove,
      getResources,
      getMilestones,
      getAsanaTasks,
      getLinksAndAccesses,
      getAppointmentLink,
      getSettings,
      getScreendesigns,
      getContactPerson,
      getDocuments,
      getProducts
    };
  }

  return {
    fetchDefaultSettings,
    getProject,
    getAllProjects,
    createProject,
    removeProject,
    withProject
  };
}
